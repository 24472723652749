// Responsive design sizes
$xl: 1140px;
$lg: 1140px;
$md: 992px;
$sm: 574px;

// Colors
$blush: #FAF6F0;
$white: #ffff;
$beige: #Be9f93;
$light-brown: #7F5B55;

$primary: $beige;
$secondary: $blush;
$third: $light-brown;
