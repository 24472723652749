.cats-list--container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .contestant--container {
    width: 100%;

    @include desktop {
      width: calc(50% - 20px);
    }

    .list-element {
      min-width: 100%;

      p {
        font-size: 1.7rem;
        margin: 15px 0 20px;
      }

      .image {
        width: 100%;
        height: 400px;
        cursor: default;
      }
    }
  }
}