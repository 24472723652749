.vote-container {
  @include desktop {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

header {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    flex-direction: column;
  
    img {
      width: 100px;
      height: 100px;
    }
  
    h1 {
      margin-top: 20px;
    }
  }
}

.contestant--container {
  position: relative;
  max-height: 100%;

  @include desktop {
    width: 50%;
    display: flex;
    justify-content: center;  
    align-items: center;
  }

  .image {
    width: 90%;
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    transition: all ease-in 0.3s;
  }
}

.app {
  min-height: 100vh;
  height: 100%;
  padding: 5% 0;
  background-color: $secondary;
}

.round--container {
  position: absolute;

  @include mobile {
    display: none;
  }
}

.homepage {
  .button {
    margin-top: 30px;
  }

  .list-link {
    display: flex;
    justify-content: center;
  }
}